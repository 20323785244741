import { ContentService } from './service-setup/ContentService';
import { createServiceManifest } from '@estee/elc-service';
import { IManifestServiceRegistryComp } from '@estee/elc-universal-utils';
import { events, serviceQueries as queries } from '@estee/elc-service-bus';
import {
    BASIC_TOUT,
    THIRD_PARTY_TOUT,
    VTO_FOUNDATION_OVERLAY,
    VTO_FOUNDATION_QR_CODE_OVERLAY,
    VIDEO_TOUT_YOUTUBE,
    CONTENT_BLOCK
} from '@estee/elc-service-view-names';

const { name, version } = __serviceInfo__;

export const serviceEvents = [
    events.VTO_FOUNDATION_OVERLAY_TOGGLED,
    events.FINDATION_BUTTON_CLICKED,
    events.CHANGE_LOCATION
];

export const serviceQueries = [queries.TEMPLATES_CONTENT_GET];

export const serviceViews = [
    BASIC_TOUT,
    CONTENT_BLOCK,
    THIRD_PARTY_TOUT,
    VTO_FOUNDATION_OVERLAY,
    VTO_FOUNDATION_QR_CODE_OVERLAY,
    VIDEO_TOUT_YOUTUBE
];

export const ServiceManifest: IManifestServiceRegistryComp = createServiceManifest<ContentService>(
    {
        views: serviceViews,
        events: serviceEvents,
        queries: serviceQueries,
        name,
        version
    },
    () => import('./service-setup/diContainer'),
    'contentService'
);
